import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginForm from './Pages/login-form/login-form';
import Reset from './Pages/reset/reset';
import Forgot from './Pages/forgot/forgot';
import SignUp from './Pages/signup/signup';
import Register from './Pages/success-message/register';
import SuccessReset from './Pages/success-message/success-reset';
import Verification from './Pages/verification/verification';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginForm />} />
        <Route path="/reset" element={<Reset />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/signup" element={< SignUp/>}/>
        <Route path='/register' element={< Register />}/>
                <Route path='/success-reset' element={< SuccessReset />}/>
        <Route path='/verify' element={< Verification />}/>

        <Route path="*" element={<LoginForm />} /> {/* Default route */}
        {/* ... other routes */}
      </Routes>
    </Router>
  );
}

export default App;
