import React ,{ useState } from 'react'
import Button from '../../Components/button';
import Header from '../../Components/header';
import Logo from '../../Components/logo';
import TextBox from '../../Components/textbox';
 import '../../Components/components.css';
import Icon from '../../Components/icon';
import { Link } from 'react-router-dom';


const LoginForm = () => {

    const [showPassword, setShowPassword] = useState(false);

  const handleInputChange = (e) => {
    // Handle input changes
  };

  const handleButtonClick = (e) => {
    // Handle button click
  };

  const togglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className='wrapper'>
         <form action="">
   <Logo className='logo' />
        <Header text='Login' />
   <div className='input-box'>
        <TextBox label='Email' placeholder='username@gmail.com' type='text' onChange={handleInputChange} required />
      </div>
      <div className='input-box password-input'>
        <TextBox label='Password' placeholder='password' type={showPassword ? 'text' : 'password'} onChange={handleInputChange} required />
        <Icon showPassword={showPassword} togglePassword={togglePassword} />
      </div>
           
       
    <div className="forgot">
    <Link to='/forgot'>Forgot Password?</Link>    
    </div> 
     <Button onClick={handleButtonClick} text='Login' />
        <div className='register-link'>
          <p>
            Don't have an account yet? <Link to='/signup'>Register</Link>    
          </p>
        </div>
    </form>
    </div>
  )
}

export default LoginForm