// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.success-wrapper {
  width: 541px;
  height: 499px;
  background: rgba(255, 255, 255, 0.1);
  /* border: 2px solid rgba(255, 255, 255, 0.2); */
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  box-shadow: 0 0 70px rgba(0, 0, 0, 0.1);
  color: #2a1e17;
  border-radius: 40px;
  padding: 20px;
  text-align: center;
}

.success-logo {
  width: 459px;
  height: auto;
  display: inline-block;

  margin-bottom: 5px;
}

.success-text {
  color: #1e1e1e;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 40px;
}

.success-wrapper button {
  width: 350px;
  height: 50px;
  background: #54779f;
  border: none;
  outline: none;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  font-family: "poppins";
  font-weight: 400;
  margin-bottom: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/success-message/success.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,oCAAoC;EACpC,gDAAgD;EAChD,mCAA2B;UAA3B,2BAA2B;EAC3B,uCAAuC;EACvC,cAAc;EACd,mBAAmB;EACnB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,qBAAqB;;EAErB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,uCAAuC;EACvC,eAAe;EACf,eAAe;EACf,WAAW;EACX,sBAAsB;EACtB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".success-wrapper {\n  width: 541px;\n  height: 499px;\n  background: rgba(255, 255, 255, 0.1);\n  /* border: 2px solid rgba(255, 255, 255, 0.2); */\n  backdrop-filter: blur(15px);\n  box-shadow: 0 0 70px rgba(0, 0, 0, 0.1);\n  color: #2a1e17;\n  border-radius: 40px;\n  padding: 20px;\n  text-align: center;\n}\n\n.success-logo {\n  width: 459px;\n  height: auto;\n  display: inline-block;\n\n  margin-bottom: 5px;\n}\n\n.success-text {\n  color: #1e1e1e;\n  text-align: center;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  margin-bottom: 40px;\n}\n\n.success-wrapper button {\n  width: 350px;\n  height: 50px;\n  background: #54779f;\n  border: none;\n  outline: none;\n  border-radius: 5px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  cursor: pointer;\n  font-size: 20px;\n  color: #fff;\n  font-family: \"poppins\";\n  font-weight: 400;\n  margin-bottom: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
