import React ,{useState} from 'react'
import Button from '../../Components/button';
import Header from '../../Components/header';
import Logo from '../../Components/logo';
import TextBox from '../../Components/textbox';
 import '../../Components/components.css';
import Icon from '../../Components/icon';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


const Reset = () => {
        const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

  const handleInputChange = (e) => {
    // Handle input changes
  };

  const handleButtonClick = (e) => {
            navigate('/success-reset');

    // Handle button click
  };

  const togglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };


  return (
    <div className='wrapper'>
        <form action="">
   <Logo />
        <Header text='Reset Password' />
    <div className='input-box'>
              <TextBox label='New Password' placeholder='new password ' type={showPassword ? 'text' : 'password'} onChange={handleInputChange} required />
        <Icon showPassword={showPassword} togglePassword={togglePassword} />

        </div>
    <div className='input-box password-input'>
              <TextBox label='Confirm Password' placeholder='confirm new password' type={showPassword ? 'text' : 'password'} onChange={handleInputChange}  required />

   
    
        <Icon showPassword={showPassword} togglePassword={togglePassword} />
           
        </div>
 
     <Button onClick={handleButtonClick} text='Reset' />
        <div className='register-link'>
          <p>
<Link to='/login-form'>Back to Login</Link>          </p>
        </div>
    </form>
    </div>
  )
}

export default Reset