import React from 'react';

const TextBox = ({ label, placeholder, type, onChange }) => {
  return (
    <div className='input-box'>
      <label className="label">{label}</label>
      <input type={type} placeholder={placeholder} required onChange={onChange} />
    </div>
  );
};

export default TextBox;
