import React from 'react'
import Button from '../../Components/button';
import Logo from '../../Components/logo';
 import './success.css';



const Register = () => {



  const handleButtonClick = (e) => {
    // Handle button click
      

  };

  return (
    <div className='success-wrapper'>
   <Logo className='success-logo'/>
 
     <div className='success-text'>
          <p>
     You have successfully registered, <br />
Click below to continue your access.      
       </p>
        </div>
     <Button onClick={handleButtonClick} text='Continue' />
    
    
    </div>
  )
}

export default Register