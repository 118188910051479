import React, { useState, useEffect} from 'react';
import Button from '../../Components/button';
import Header from '../../Components/header';
import Logo from '../../Components/logo';
import './verification.css';
import { Link } from 'react-router-dom';

const Verification = () => {

  const [timer, setTimer] = useState(120);
  const [isResendDisabled, setResendDisabled] = useState(true);

    // const inputRefs = [useRef(), useRef(), useRef(), useRef()];

const handleInput = (e) => {
  const targetInput = e.target;

  if (targetInput.value.trim() !== '') {
    targetInput.classList.add('filled');
  } else {
    targetInput.classList.remove('filled');
  }

  // Add logic to automatically move focus to the next input
  const maxLength = parseInt(targetInput.getAttribute('maxLength'), 10);
  if (targetInput.value.length === maxLength) {
    const nextInput = targetInput.nextElementSibling;
    if (nextInput) {
      nextInput.focus();
    }
  }
};

  const handleButtonClick = (e) => {
    // Handle button click
  };

  const handleResendClick = () => {
    // Handle resend click
    // if (timer === 0) {
    //   setTimer(120); // Reset the timer
    //   setResendDisabled(true); // Disable resend link
    // }
  };

  useEffect(() => {
    // Decrease timer every second
    const interval = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Enable resend link when the timer reaches 00
    if (timer === 0) {
      setResendDisabled(false);
    }
  }, [timer]);

  // Convert seconds to minutes and seconds
  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;

  const resendLinkStyle = {
    opacity: isResendDisabled ? 0.5 : 1,
    cursor: isResendDisabled ? 'not-allowed' : 'pointer',
  };

  return (
    <div className='ver-wrapper'>
      <form action="">
        <Logo className='ver-logo' />
        <Header text='Verification Code' />
        <div className="text">
          <p>
            We have sent a verification code to your <br />
            <span className='email-bold'> Email Address</span>
          </p>
        </div>

<div className="otp-input-container">
  {/* Your OTP input boxes go here */}
  <input type="text" maxLength="1" onInput={handleInput} />
  <input type="text" maxLength="1" onInput={handleInput} />
  <input type="text" maxLength="1" onInput={handleInput} />
  <input type="text" maxLength="1" onInput={handleInput} />
</div>

        
        {/* Timer */}
        <div className="timer-container">
          <p>
            {String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
          </p>
        </div>

        {/* Resend Link */}
        <div className='resend-link' style={resendLinkStyle}>
          <p>
            Don't receive the code?
            <Link to='' onClick={handleResendClick} style={resendLinkStyle}>
              Resend
            </Link>
          </p>
        </div>

        <Button onClick={handleButtonClick} text='Verify' />
        <div className='login-link'>
          <p>Already have an account?
            <Link to='/login-form'>Login</Link>
          </p>
        </div>
      </form>
    </div>
  );
}

export default Verification;
