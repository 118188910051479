import React ,{ useState } from 'react'
import Button from '../../Components/button';
import Header from '../../Components/header';
import Logo from '../../Components/logo';
import TextBox from '../../Components/textbox';
 import './signup.css';
import Icon from '../../Components/icon';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


const SignUp = () => {
        const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

  const handleInputChange = (e) => {
    // Handle input changes
  };

  const handleButtonClick = (e) => {
        navigate('/register');

    // Handle button click
  };

 const togglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className='sign-wrapper'>
        <form action="">
   <Logo className='sign-logo' />
        <Header text='Signup' />
        <div className='input-box'>
              <TextBox label='First Name' placeholder='first name' type='text' onChange={handleInputChange} required />

        </div>

             <div className='input-box'>
              <TextBox label='Last Name' placeholder='last name' type='text' onChange={handleInputChange} required />

        </div>

    <div className='input-box'>
              <TextBox label='Email' placeholder='username@gmail.com' type='text' onChange={handleInputChange} required />

        </div>
    <div className='input-box password-input'>
              <TextBox label='Password' placeholder='password' type={showPassword ? 'text' : 'password'} onChange={handleInputChange}  required />

   
 
        <Icon showPassword={showPassword} togglePassword={togglePassword} />
           
        </div>

        <div className='input-box password-input'>
              <TextBox label='Confirm Password' placeholder='confirm password' type={showPassword ? 'text' : 'password'} onChange={handleInputChange}  required />
 
        <Icon showPassword={showPassword} togglePassword={togglePassword} />
           
        </div>
   
     <Button onClick={handleButtonClick} text='Signup' />
        <div className='register-link'>
          <p>
            Already have an account ? <Link to='/login-form'>Login</Link>    
          </p>
        </div>
    </form>
    </div>
  )
}

export default SignUp