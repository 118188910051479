import React from 'react'
import Button from '../../Components/button';
import Header from '../../Components/header';
import Logo from '../../Components/logo';
import TextBox from '../../Components/textbox';
 import './forgot.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


const Forgot = () => {

      const navigate = useNavigate();

  const handleInputChange = (e) => {
    // Handle input changes
  };

  const handleButtonClick = (e) => {
    // Handle button click
        navigate('/reset');

  };

  return (
    <div className='forgot-wrapper'>
        <form action="">
   <Logo className='forgot-logo'/>
        <Header text='Forgot Password' />
 <div className='input-box'>
              <TextBox label='Email' placeholder='username@gmail.com' type='text' onChange={handleInputChange} required />

        </div>
 
     <Button onClick={handleButtonClick} text='Send Email' />
        <div className='register-link'>
          <p>
<Link to='/login-form'>Back to Login</Link>          </p>
        </div>
    </form>
    </div>
  )
}

export default Forgot