import React, { useState } from 'react';

const Icon = ({ showPassword, togglePassword }) => {
  return (
    <div className='icon' onClick={togglePassword}>
      <svg xmlns="http://www.w3.org/2000/svg" width='16' height='16' viewBox="0 0 16 16" fill='none'>
        {showPassword ? (
          <path d="M11.1955 9.06667C11.3232 8.72558 11.3879 8.36418 11.3866 8C11.3866 7.19138 11.0654 6.41589 10.4936 5.84411C9.92187 5.27233 9.14637 4.95111 8.33776 4.95111C7.97806 4.95153 7.62134 5.01625 7.28442 5.14222L7.99998 5.88C8.10875 5.8626 8.21871 5.85368 8.32887 5.85333C8.90062 5.85214 9.44961 6.07722 9.85598 6.47942C10.2623 6.88162 10.4931 7.42827 10.4978 8C10.4974 8.11015 10.4885 8.22011 10.4711 8.32889L11.1955 9.06667Z" fill="#C7D2D6"/>
        ) : (
          <path d="M15.24 7.79111C13.7422 5.02222 11.1156 3.34666 8.20889 3.34666C7.41749 3.34852 6.63141 3.47604 5.88 3.72444L6.59556 4.44444C7.12261 4.30823 7.66454 4.23807 8.20889 4.23555C10.7111 4.23555 12.9867 5.63111 14.3422 7.98222C13.845 8.85449 13.1858 9.62382 12.4 10.2489L13.0311 10.88C13.9405 10.1464 14.6957 9.24008 15.2533 8.21333L15.3689 8L15.24 7.79111Z" fill="#C7D2D6"/>
        )}
        <path d="M2.16447 2.56889L4.14669 4.55111C2.89376 5.35783 1.86976 6.47368 1.17336 7.79111L1.0578 8L1.17336 8.21334C2.67113 10.9822 5.2978 12.6578 8.20446 12.6578C9.33898 12.6575 10.4588 12.4008 11.48 11.9067L13.7022 14.1289L14.48 13.4622L2.92447 1.90667L2.16447 2.56889ZM6.4978 6.90222L9.45335 9.85778C9.11941 10.0645 8.73498 10.1752 8.34224 10.1778C8.05688 10.1778 7.77433 10.1214 7.51085 10.0118C7.24738 9.90215 7.00818 9.74152 6.80702 9.53912C6.60585 9.33672 6.4467 9.09654 6.33872 8.83239C6.23073 8.56825 6.17604 8.28536 6.1778 8C6.18281 7.61181 6.29343 7.23231 6.4978 6.90222ZM5.85335 6.25778C5.43755 6.84418 5.24265 7.5588 5.30318 8.27511C5.36371 8.99142 5.67576 9.6632 6.18407 10.1715C6.69238 10.6798 7.36416 10.9919 8.08047 11.0524C8.79678 11.1129 9.5114 10.918 10.0978 10.5022L10.8089 11.2133C9.9857 11.5652 9.09973 11.7466 8.20446 11.7467C5.70224 11.7467 3.42669 10.3511 2.07113 8C2.72167 6.8481 3.65857 5.88365 4.79113 5.2L5.85335 6.25778Z" fill="#C7D2D6"/>
      </svg>
    </div>
  );
};

export default Icon;
